import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import Form from "../components/Form";
import Headline from "../components/Headline";

export default function Contact() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {name: {eq: "mailboxes"}}) {
        nodes {
          publicURL
          childrenImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);
  const image = data.allFile.nodes[0];
  return (
    <Layout
      title="Contact Me"
      description="Contact Bobby Saul."
      image={image.publicURL}
    >
      <Headline headlineText='Contact Me' backgroundImage={image.publicURL} />
      <p>Please, feel free to contact me and leave a message. I will try my best to get back to you in an appropriate amount of time.</p>
      <Form />
    </Layout>
  )
}
