import React, { useState, useEffect } from 'react';
import validateEmail from '../functions/validateEmail';
import '../styles/form.scss';

const POST_URL = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSe2tY3S853Nr5dBiYrOD2e_xdK5MM0xh5Jxq5_ompGshFrHkg/formResponse";

export default function Form() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [nameValid, setNameValid] = useState(false);
  const [nameInitial, setNameInitial] = useState(true);
  const [emailValue, setEmailValue] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [emailInitial, setEmailInitial] = useState(true);
  const [commentValue, setCommentValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const defaultName = localStorage.getItem('nameValue');
    const defaultEmail = localStorage.getItem('emailValue');
    if (defaultName) {
      setNameValue(defaultName);
      setNameValid(true);
    }
    if (defaultEmail) {
      setEmailValue(defaultEmail);
      setEmailValid(true);
    }
  }, []);

  function checkName(e) {
    const value = e.target.value;
    var valid = false;
    if (value.trim().length > 0) {
      valid = true;
    }
    setNameValue(value);
    setNameValid(valid);
    setNameInitial(false);
  }

  function checkEmail(e) {
    const value = e.target.value.trim();
    var valid = false;
    valid = validateEmail(value);
    setEmailValue(value);
    setEmailValid(valid);
    setEmailInitial(false);
  }

  function submitForm(e) {
    e.preventDefault();
    if (nameValid && emailValid && !isSubmitting) {
      setIsSubmitting(true);
      localStorage.setItem('nameValue', nameValue);
      localStorage.setItem('emailValue', emailValue);
      fetch(POST_URL, {
        method: 'post',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        mode: "no-cors",
        body: new URLSearchParams({
          "entry.234495092": nameValue,
          "entry.834188984": emailValue,
          "entry.1522508967": commentValue
        }).toString()
      }).then(response => {
        setIsSubmitting(false);
        setSubmitted(true);
      }).catch(error => {
        console.error(error);
        setErrorMessage((
          <p>An error has occurred. Please, try again, and if the error persists email me at <a href="mailto:bobby@bobbysaul.com">bobby@bobbysaul.com</a>.</p>
        ));
        setIsSubmitting(false);
      });
    }
  }

  return submitted ? (<p>Thank you! I will be in contact with you shortly.</p>) : (
    <form onSubmit={submitForm} className={isSubmitting ? "submitting" : ""}>
      {errorMessage}
      <div className={(nameValid || nameInitial ? "valid" : "invalid") + " input-wrapper"}>
        <label className="required" htmlFor="form-name">Name:</label>
        <input id="form-name" name="name" type="text" value={nameValue} onChange={checkName} required />
      </div>
      <div className={(emailValid || emailInitial ? "valid" : "invalid") + " input-wrapper"}>
        <label className="required" htmlFor="form-email">Email:</label>
        <input id="form-email" name="email" type="email" value={emailValue} onChange={checkEmail} required />
      </div>
      <div className="input-wrapper">
        <label htmlFor="form-comment">Comment:</label>
        <textarea id="form-comment" name="comment" value={commentValue} onChange={(e) => {
          setCommentValue(e.target.value);
        }}></textarea>
      </div>
      <div className="input-wrapper">
        <input type="submit" value="Submit" disabled={!nameValid || !emailValid} />
      </div>
    </form>
  )
};
